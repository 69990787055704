import React from 'react';
import { useTranslation } from 'react-i18next';

const FooterContent = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <div className="w-full flex whitespace-nowrap lg:justify-between items-center text-sm">
      <div className="hidden flex-1 md:flex gap-3 justify-start">
        <div className="flex gap-1 justify-between items-center text-center">
          <i className="fas fa-location-dot"></i>
          <p>{t('footer.address')}</p>
        </div>  
      </div>
      <div className="flex-1 flex gap-3 justify-center items-center">
        <p>{t('footer.copyright', { year: currentYear })}</p>
        <a href="https://fi.linkedin.com/company/pasram-oy?trk=similar-pages" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
        <a href="https://www.facebook.com/PasramOy/" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
      </div>
      <div className="hidden flex-1 md:flex gap-3 justify-end">
        <div className="flex gap-1 justify-between items-center text-center">
          <i className="fas fa-phone"></i>
          <p>{t('footer.phone')}</p>
        </div>
        <div className="flex gap-1 justify-between items-center text-center">
          <i className="fas fa-envelope"></i>
          <p>{t('footer.email')}</p>
        </div>        
      </div>
    </div>
  );
};

export default FooterContent;
